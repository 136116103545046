import { DonationStatus, MatchApprovalStatus, MatchableDonationModelResponse, PlatformDonationStatus } from "@fieldday/fielddayportal-model"
import { Button, Chip, Grid, SxProps, Theme, Typography, useTheme } from "@mui/material"
import { Link } from "react-router-dom"
import { NPO } from "../../models/Npo"
import { OrgUnit } from "../../models/OrgUnit"
import { UserInfo } from "../../models/UserInfo"
import useGlobalStyles from "../../styles/globalStyles"
import { objFromTzAsUtc } from "../../util/dateUtil"
import { DonationUiStatus, getDonationUiStatus } from "../../util/matchableDonationUtils"
import { roundMinutesToHours } from "../../util/mathUtil"
import { dollarCents, pluralizeWithNum } from "../../util/stringUtil"
import { OpenDonationModal } from "./MatchableDonationModal"

export default function MatchableDonationListItem(
  { donation, adminView, user, nonProfitOrg, orgUnit, selectDonation, setOpenModal }:
{
  donation: MatchableDonationModelResponse,
  adminView: boolean,
  user?: UserInfo,
  nonProfitOrg?: NPO,
  orgUnit?: OrgUnit,
  selectDonation: () => void,
  setOpenModal: (openModal: OpenDonationModal) => void,
}) {
  const globalStyles = useGlobalStyles()
  const theme = useTheme()

  const platformDonation = donation.platformDonations && donation.platformDonations[0]
  const donationAmount = donation.amount ?? platformDonation?.intendedAmountCents
  const volunteerTime = donation.minutesVolunteered && roundMinutesToHours(donation.minutesVolunteered)
  const matchAmount = (() => {
    if (platformDonation?.status === PlatformDonationStatus.Failed) return  "—"
    if (donation.approvalStatus === MatchApprovalStatus.Pending) return  "Pending"
    return dollarCents(donation.approvedAmount)
  })()

  const uiStatus = getDonationUiStatus(donation, false)

  return (
    <Grid key={donation.id} container columnSpacing={2} sx={{ marginTop: "2em", minWidth: "630px" }}>
      <Grid item xs={1.75}>
        <Typography color={theme.palette.text.secondary}>
          {objFromTzAsUtc(donation.dateISO).format("MMM DD, YYYY")}
        </Typography>
      </Grid>
      <Grid item xs={3.5}>
        <Link key={donation.id} to={`${location.pathname}${location.search}`} onClick={() => {
          selectDonation()
          setOpenModal(OpenDonationModal.DETAILS)
        }}>
          <Typography className={`${globalStyles.donationListItem} npo`}>
            {nonProfitOrg?.name}
          </Typography>
          {adminView &&
            <Typography className={`${globalStyles.donationListItem} donorInfo`}>
              {orgUnit?.name ? orgUnit.name : `${user && `${user.firstName} ${user.lastName}` + ' | '}${user?.email}`}
            </Typography>
          }
        </Link>

      </Grid>
      <Grid item xs={1.5}>
        <Typography
          color={(donation.amount || platformDonation?.intendedAmountCents || donation.minutesVolunteered)
            ? theme.palette.text.primary : theme.palette.text.secondary}
          fontStyle={platformDonation?.status === PlatformDonationStatus.Created ? "oblique 10deg" : "auto"}
          sx={{ textDecoration: platformDonation?.status === PlatformDonationStatus.Failed ? "line-through" : "none" }}
        >
          {volunteerTime ? `${pluralizeWithNum(volunteerTime, "hour")}` : dollarCents(donationAmount)}
        </Typography>
      </Grid>
      <Grid item xs={1.5}>
        <Typography color={donation.approvalStatus === MatchApprovalStatus.Pending ? theme.palette.text.secondary : theme.palette.text.primary}>
          {matchAmount}
        </Typography>
      </Grid>
      <Grid item xs={2.25}>
        <DonationStatusChip donation={donation} adminView={adminView} />
      </Grid>
      <Grid item xs={1.5}>
        {(adminView && donation.status === DonationStatus.Reported) &&
          <Button
            variant="outlined"
            sx={{ marginTop: "-.5rem" }}
            onClick={() => {
              selectDonation()
              setOpenModal(OpenDonationModal.MANAGE)
            }}
          >
            Manage
          </Button>
        }
        {(!adminView
          && donation.status === DonationStatus.Reported
          && uiStatus !== DonationUiStatus.Completed
          && donation.minutesVolunteered == null
        ) &&
          <Button
            variant="outlined"
            sx={{ marginTop: "-.5rem" }}
            onClick={() => {
              selectDonation()
              setOpenModal(OpenDonationModal.UPDATE)
            }}
          >Edit</Button>
        }
      </Grid>
    </Grid>
  )
}

export function DonationStatusChip({ donation, sx, adminView }:
  { donation: MatchableDonationModelResponse, sx?: SxProps<Theme>, adminView?: boolean })
{
  const donationStatus = getDonationUiStatus(donation, adminView)
  const chipColor = (() => {
    switch (donationStatus) {
      case DonationUiStatus.Declined:
        return undefined // grey
      case DonationUiStatus.PaymentPending:
        return undefined
      case DonationUiStatus.PaymentFailed:
        return "warning"
      case DonationUiStatus.Matched:
        return "primary"
      case DonationUiStatus.Completed:
        return "primary"
      case DonationUiStatus.Approved:
        return "success"
      default: // Made, Reported
        return "secondary"
    }
  })()

  return <Chip sx={sx} label={donationStatus} size="small" color={chipColor} />
}
